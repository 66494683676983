import React from 'react';
import PropTypes from 'prop-types';

import ImgixImage from '../ImgixImage';

const CollectionImage = (props) => {
  const {
    blok: { fit, fill, sizes = '(min-width: 768px) 50vw, 100vw', collection },
  } = props;

  const usedCollection = collection || props.collection;

  return usedCollection?.image ? (
    <ImgixImage
      src={usedCollection.image.originalSrc}
      srcSetOptions={{ q: 75, auto: ['format', 'compress'] }}
      fit={fit}
      fill={fill}
      sizes={sizes}
    />
  ) : null;
};

CollectionImage.propTypes = {
  blok: PropTypes.shape({
    fit: PropTypes.PropTypes.oneOf(['fit', 'fill', '']),
    fill: PropTypes.bool,
    sizes: PropTypes.string,
    collection: PropTypes.object,
  }),
  collection: PropTypes.object,
};

export default CollectionImage;
